import React from 'react';
import { graphql } from 'gatsby';
import CardElement from '../components/CardElement';
import Layout from '../components/Layout';
import Pagination from '../components/Pagination';

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allMarkdownRemark(
      sort: { order: DESC, fields: [frontmatter___date] }
      filter: { frontmatter: { type: { eq: "blog" } } }
      limit: $limit
      skip: $skip
		) {
      edges {
        node {
          id
          excerpt(pruneLength: 250)
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            slug
			      title
            description
            featuredImage
          }
        }
      }
    }
  }
`

class BlogList extends React.Component {
  render() {
    const { data, pageContext } = this.props;

    const posts = data.allMarkdownRemark.edges
      .filter(edge => !!edge.node?.frontmatter?.date)
      .map(post => {
        const element = post.node.frontmatter
        return (
          <CardElement
            key={post.node?.id}
            image={element?.featuredImage}
            title={element?.title}
            subtitle={element?.subtitle}
            date={element?.date}
            slug={element?.slug}
          />
        )
      })
    return (
      <Layout fullMenu>
      <article id="main">
          <header>
              <h2>Blog</h2>
          </header>
          <section className="wrapper style5">
              <div className="inner posts">
                  <section>
                  {posts.length === 0 ?
                      <p>This section is empty.</p>
                    :
                      <div className="grid">
                        {posts}
                      </div>
                    }
                  </section>
              </div>
              {posts.length > 0 && <Pagination context={pageContext} slug='/blog' />}
          </section>
      </article>
    </Layout>
    )
  }
}

export default BlogList;
